<script setup lang="ts">
import DataTable from '@app/components/ui/table/DataTable.vue'
import FromToDate from '@app/components/ui/table/column/FromToDate.vue'
import type { ResourceCollection } from '@app/types/shared'
import type { ShiftAvailability } from '@app/types/shifts'
import { type PropType } from 'vue'

defineProps({
    shift_availabilities: {
        type: Object as PropType<ResourceCollection<ShiftAvailability>>,
        required: true,
    },
})

const columns = [
    {
        type: 'string',
        header: 'attributes.id',
        properties: { name: 'id' },
    },
    {
        type: 'string',
        header: 'attributes.full_name',
        properties: { name: 'user_full_name' },
    },
    {
        type: 'string',
        header: 'attributes.work_type',
        properties: { name: 'work_type' },
    },
    {
        type: 'string',
        header: 'attributes.client_project',
        properties: { name: 'client_project' },
    },
    {
        type: 'string',
        header: 'attributes.location_type',
        properties: { name: 'location_type' },
    },
    {
        type: 'string',
        header: 'attributes.branch_id',
        properties: { name: 'branch' },
    },
    {
        name: 'date',
        header: 'attributes.date',
    },
    {
        type: 'multi-line-date',
        header: 'attributes.created_at',
        properties: { name: 'created_at' },
        sort: 'created_at',
    },
]
</script>

<template>
    <div class="scrollbar-hiden flex items-start gap-4">
        <DataTable
            class="lg:inline-block' flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :items="shift_availabilities"
            :columns="columns"
            only="shift_availabilities"
            :clickable="false"
        >
            <template v-slot:column.date="{ item }">
                <FromToDate :from="item.from" :to="item.to" class="gap-2" />
            </template>
        </DataTable>
    </div>
</template>
