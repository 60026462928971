<script setup lang="ts">
import { useLineChartData } from '@app/composables/chartjs/useLineChartData'
import { chartDefaults } from '@app/utils/chartSettings'
import { ArrowTrendingDownIcon, ArrowTrendingUpIcon } from '@heroicons/vue/24/solid'
import type { ChartData } from 'chart.js'
import { defineProps } from 'vue'
import { Line } from 'vue-chartjs'

chartDefaults()

interface GraphCardProps {
    graph: {
        name: string
        range: string
        data: ChartData<'line'>
    }
}

const { graph } = defineProps<GraphCardProps>()

const { chartData, options, lastValue, change } = useLineChartData(graph.data)
</script>

<template>
    <div class="bg-white-800 w-full rounded-lg border border-zinc-100 p-4">
        <div class="flex flex-col justify-between gap-2">
            <div class="flex flex-wrap items-start justify-between">
                <h3 class="w-1/2 text-base font-medium leading-6 text-zinc-600">{{ $t(graph.name) }}</h3>
                <div class="text-sm font-medium uppercase leading-5 text-zinc-400">{{ $t(graph.range) }}</div>
            </div>
            <div class="flex items-center gap-4">
                <p class="text-2xl font-semibold leading-8 text-gray-900" v-tooltip="$t('dashboard.current_period')">{{ lastValue }}</p>
                <div
                    v-if="change !== 0"
                    :class="[change >= 0 ? 'text-green-500' : 'text-red-500', 'flex items-center gap-1']"
                    v-tooltip="$t('dashboard.change_period')"
                >
                    <div :class="[change >= 0 ? 'bg-green-100' : 'bg-red-100', 'flex h-6 w-6 items-center justify-around rounded-full']">
                        <ArrowTrendingUpIcon v-if="change >= 0" class="h-5 w-5 text-green-500" />
                        <ArrowTrendingDownIcon v-else class="h-5 w-5 text-red-500" />
                    </div>
                    <span>{{ change }}</span>
                </div>
            </div>
        </div>
        <div class="">
            <Line :data="chartData" :options="options" />
        </div>
    </div>
</template>
